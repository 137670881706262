<template>
  <card :titulo="nomeGuia" v-if="estadoPossuiGuia">

    <card-content class="mb-2" :background="true">
      <div class="row gutters-xs">

        <v-input type="mask" class="col" :error="validation.guia" :disabled="!edicao"
                 label="Número da Guia" v-model="item.guia"
                 :masks="mascaras"
                 :inputProps="{ 'maxlength': tamanhoGuia}" @change="validarGuia()"/>

        <div class="w-100" />

        <v-input type="v-select" :options="tiposTribunal" class="col-12" v-if="tiposTribunal.length" :disabled="!edicao"
                 label="Natureza" v-model="item.codigoTj" />

      </div>
    </card-content>

  </card>
</template>

<script>
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
export default {
  name: "VinculoGuiaTribunal",
  components: {Card, CardContent},
  props:{
    item:Object,
    edicao:Boolean,
    dominio:{type: String, default: ""},
    tipo:String,
    validation:{type: Object, default: () => ({})},
  },
  data(){
    return {
      tiposTribunal: [],
    }
  },

  async created() {

    if(this.$root.isEstadoPE){
      let tiposTribunal = await TipoServicoBusiness.getTiposAtoTribunal(/*this.dominio*/);
      if (!this.item?.codigoTj) {
        this.item.codigoTj = (((tiposTribunal || []).find(e => e.id === this.item?.tipoServico?.tipoTribunal))?.codigo) || '';
        this.item.codigoTj = this.item.codigoTj + '';
      }
      this.tiposTribunal = tiposTribunal.map(t => {
        return {id: t.codigo + '', nome: t.nome}
      });
    }

  },

  computed: {

    mascaras(){
      if(this.$root.isEstadoPE){
        return ['########'];
      }
      if(this.$root.isEstadoBA){
        return ['####.###.######'];
      }
      if(this.$root.isEstadoSE){
        return ['####.###.######'];
      }
    },

    estadoPossuiGuia(){
      return this.$root.isEstadoPE || this.$root.isEstadoBA || this.$root.isEstadoSE;
    },

    nomeGuia(){

      if(this.$root.isEstadoPE){
        return 'SICASE';
      }

      if(this.$root.isEstadoBA || this.$root.isEstadoSE){
        return 'DAJE';
      }

    },

    tamanhoGuia(){

      if(this.$root.isEstadoPE){
        return 8;
      }

      if(this.$root.isEstadoBA){
        return 15;
      }

      if(this.$root.isEstadoSE){
        return 15;
      }

    }
  },

  methods:{
    validarGuia(){
      let guia = this.item.guia;
      this.$set(this.validation, 'guia', null);
      if(guia.length < this.tamanhoGuia){
        this.$set(this.validation, 'guia', 'Número da Guia com quantidade de caracteres inválidos');
      }
    }
  }

}
</script>
